import React from "react";
import { Box, Typography, Grid, Paper, Button, Alert } from "@mui/material";
import { Link } from "react-router-dom";
import useGlobalStyles from "../../styles";

const CaseStudies: React.FC = () => {
  const { globalStyles, isSmallScreen } = useGlobalStyles();

  const styles = {
    ...globalStyles,
    container: {
      ...globalStyles.container,
      textAlign: "left", // Ensure left justification
    },
    caseStudy: {
      marginBottom: "2rem",
    },
    paper: {
      padding: "1.5rem",
      height: "100%",
    },
    sectionTitle: {
      marginBottom: "1rem",
      fontFamily: "SFBold",
      fontSize: "1.75rem",
    },
    highlight: {
      color: "#1976d2",
      fontWeight: "bold",
    },
    ctaButton: {
      marginTop: "2rem",
      padding: "1rem 2rem",
      fontSize: "1.2rem",
    },
    disclaimer: {
      marginBottom: "2rem",
    },
  };

  const caseStudies = [
    {
      title: "Revolutionizing Online Sales for a Major Coffee Chain",
      company: "MegaBrew Coffee",
      challenge:
        "A leading coffee chain struggling to differentiate its online offerings and personalize the e-commerce experience for customers.",
      solution:
        "Implemented PerfectBlend AI system to offer customized blends through their website and mobile app.",
      results: [
        "250% increase in online sales within the first year",
        "Customer retention rate improved from 50% to 80% for online shoppers",
        "Reduced inventory waste by 35% through AI-driven demand prediction and only blending to order",
        "Expanded online product offerings from 20 standard blends to millions possible combinations",
      ],
    },
    {
      title: "Boosting Capacity and Variety for a Macro Roaster",
      company: "Sunrise Roasters",
      challenge:
        "A large-scale roaster looking to increase daily production capacity and offer more diverse product options.",
      solution:
        "Integrated PerfectBlend AI with existing roasting systems to optimize production and create custom blends.",
      results: [
        "Increased daily roasting capacity from 5 tons to 9 tons",
        "Introduced a 'Design Your Blend' feature, resulting in 60% of online orders being custom blends",
        "Reduced operational costs by 20% through improved efficiency",
        "Expanded market reach, with 30% of new customers attracted by personalization options",
      ],
    },
    {
      title: "Enhancing Direct-to-Consumer Sales for a Specialty Roaster",
      company: "Artisan Coffee Co.",
      challenge:
        "A mid-sized specialty roaster aiming to expand its direct-to-consumer online sales without compromising quality.",
      solution:
        "Adopted PerfectBlend AI to offer personalized recommendations and custom blends through their e-commerce platform.",
      results: [
        "250% growth in online direct-to-consumer sales",
        "Average order value increased by 35% due to personalized offerings",
        "Customer satisfaction scores rose from 4.2 to 4.8 out of 5",
        "Reduced customer acquisition cost by 50% through improved targeting and retention",
      ],
    },
  ];

  return (
    <Box sx={styles.container}>
      <Typography variant="h2" sx={styles.title}>
        Case Studies
      </Typography>
      <Typography sx={styles.tagline}>
        Discover how PerfectBlend AI is transforming online coffee businesses.
      </Typography>

      <Alert severity="info" sx={styles.disclaimer}>
        <Typography variant="body1">
          Note: The following case studies are hypothetical scenarios created to
          illustrate potential applications and benefits of PerfectBlend AI for
          online coffee businesses and large-scale roasters.
        </Typography>
      </Alert>

      <Grid container spacing={4}>
        {caseStudies.map((study, index) => (
          <Grid item xs={12} key={index}>
            <Paper sx={styles.paper}>
              <Typography variant="h4" sx={styles.sectionTitle}>
                {study.title}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {study.company}
              </Typography>

              <Typography variant="h6" sx={styles.highlight} gutterBottom>
                Challenge:
              </Typography>
              <Typography paragraph>{study.challenge}</Typography>

              <Typography variant="h6" sx={styles.highlight} gutterBottom>
                Solution:
              </Typography>
              <Typography paragraph>{study.solution}</Typography>

              <Typography variant="h6" sx={styles.highlight} gutterBottom>
                Results:
              </Typography>
              <ul>
                {study.results.map((result, idx) => (
                  <li key={idx}>
                    <Typography>{result}</Typography>
                  </li>
                ))}
              </ul>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/get-started"
          sx={styles.ctaButton}
        >
          Elevate Your Online Coffee Business
        </Button>
      </Box>
    </Box>
  );
};

export default CaseStudies;
