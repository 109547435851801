import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import emailjs from "emailjs-com";
import useGlobalStyles from "../../styles";
const Contact: React.FC = () => {
  const { globalStyles, isSmallScreen } = useGlobalStyles();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  const styles = {
    ...globalStyles,
    section: {
      marginTop: "2rem",
    },
    sectionTitle: {
      marginBottom: "1rem",
      fontFamily: "SFBold",
      fontSize: "1.75rem",
    },
    paper: {
      padding: "2rem",
      marginTop: "2rem",
    },
    formField: {
      marginBottom: "1.5rem",
    },
    submitButton: {
      marginTop: "1rem",
      padding: "0.75rem",
      fontSize: "1.1rem",
    },
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear error when user types
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.fullName) newErrors.fullName = "Full name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.subject) newErrors.subject = "Subject is required";
    if (!formData.message) newErrors.message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsLoading(true);
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_CONTACT_EMAILJS_TEMPLATE_ID;
    const userId = process.env.REACT_APP_EMAILJS_USER_ID;
    if (!serviceId || !templateId || !userId) {
      console.error(
        "EmailJS configuration is incomplete. Please check your environment variables."
      );
      setSnackbar({
        open: true,
        message:
          "Sorry, there was a problem sending your message. Please try again later.",
        severity: "error",
      });
      setIsLoading(false);
      return;
    }

    try {
      await emailjs.sendForm(serviceId, templateId, e.currentTarget, userId);
      setSnackbar({
        open: true,
        message: "Thank you for your message! We will get back to you soon.",
        severity: "success",
      });
      setFormData({
        fullName: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error("EmailJS error:", error);
      setSnackbar({
        open: true,
        message:
          "Oops! Something went wrong. Please try again or contact us directly.",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Box sx={styles.container}>
      <Typography variant="h2" sx={styles.title}>
        Contact Us
      </Typography>
      <Typography sx={styles.tagline}>
        We're here to answer your questions and provide support for PerfectBlend
        AI
      </Typography>

      <Paper sx={styles.paper} elevation={3}>
        <Typography variant="h4" sx={styles.sectionTitle}>
          Send Us a Message
        </Typography>
        <Typography paragraph>
          Have a question or comment? Fill out the form below, and we'll get
          back to you as soon as possible.
        </Typography>

        <form onSubmit={sendEmail}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                fullWidth
                required
                error={!!errors.fullName}
                helperText={errors.fullName}
                sx={styles.formField}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email Address"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                fullWidth
                required
                error={!!errors.email}
                helperText={errors.email}
                sx={styles.formField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Subject"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                fullWidth
                required
                error={!!errors.subject}
                helperText={errors.subject}
                sx={styles.formField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                multiline
                rows={4}
                fullWidth
                required
                error={!!errors.message}
                helperText={errors.message}
                sx={styles.formField}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={isLoading}
            sx={styles.submitButton}
          >
            {isLoading ? <CircularProgress size={24} /> : "Send Message"}
          </Button>
        </form>

        <Typography variant="body2" sx={{ mt: 2 }}>
          By submitting this form, you agree to our{" "}
          <Link href="/privacy-policy">Privacy Policy</Link>.
        </Typography>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default Contact;
