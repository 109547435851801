import React from "react";
import { Box, Typography, Grid, Paper, Button } from "@mui/material";
import { Link } from "react-router-dom";
import useGlobalStyles from "../../styles";

const HowItWorks: React.FC = () => {
  const { globalStyles, isSmallScreen } = useGlobalStyles();

  const styles = {
    ...globalStyles,
    container: {
      ...globalStyles.container,
      textAlign: "left", // Set default text alignment to left
    },
    section: {
      marginTop: "2rem",
    },
    sectionTitle: {
      marginBottom: "1rem",
      fontFamily: "SFBold",
      fontSize: "1.75rem",
    },
    paper: {
      padding: "1rem",
      height: "100%",
    },
    highlight: {
      color: "#1976d2",
      fontWeight: "bold",
    },
    stepNumber: {
      fontSize: "2.5rem",
      fontWeight: "bold",
      color: "#1976d2",
      marginRight: "1rem",
    },
    image: {
      maxWidth: "100%",
      height: "auto",
      borderRadius: "8px",
      // boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    },
    ctaButton: {
      marginTop: "2rem",
      padding: "1rem 2rem",
      fontSize: "1.2rem",
    },
    listItem: {
      marginBottom: "0.5rem",
    },
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>How PerfectBlend AI Works</Typography>
      <Typography sx={styles.tagline}>
        From flavor profile to the perfect blend in your hands
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Typography variant="h5" sx={styles.sectionTitle}>
              Our AI-Powered Custom Blending Process
            </Typography>
            <Typography paragraph>
              PerfectBlend AI combines advanced AI technology with precision
              engineering to create personalized coffee experiences. Here's how
              we turn your customers' preferences into the perfect cup of
              coffee:
            </Typography>
          </Paper>
        </Grid>

        {/* Step 1 */}
        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Box display="flex" alignItems="center">
              <Typography sx={styles.stepNumber}>1</Typography>
              <Typography variant="h5" sx={styles.sectionTitle}>
                Enter Flavor Profile
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography paragraph>
                  Your customers use our customizable web app to enter their
                  flavor profile (FP), specifying preferences for:
                </Typography>
                <ul>
                  <li style={styles.listItem}>Mouthfeel</li>
                  <li style={styles.listItem}>Acidity</li>
                  <li style={styles.listItem}>Caramelization</li>
                  <li style={styles.listItem}>Fruitiness</li>
                  <li style={styles.listItem}>Floral/Earthiness</li>
                </ul>
                <Typography paragraph>
                  The app can be fully tailored to match your brand's existing
                  online identity or create a new experience. Even the flavor
                  profile components can be customized to your specifications.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ textAlign: "center" }}>
                  <img
                    src="/images/how/step1.png"
                    alt="Step 1: Enter Flavor Profile"
                    style={styles.image}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Step 2 */}
        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Box display="flex" alignItems="center">
              <Typography sx={styles.stepNumber}>2</Typography>
              <Typography variant="h5" sx={styles.sectionTitle}>
                AI Analysis and Blend Creation
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography paragraph>
                  The flavor profile is sent to our server for analysis. Our
                  proprietary software, using unique GPTs and algorithms,
                  creates the perfect blend from your coffee inventory. This
                  process involves:
                </Typography>
                <ul>
                  <li style={styles.listItem}>
                    Analyzing the customer's flavor preferences
                  </li>
                  <li style={styles.listItem}>
                    Evaluating the profiles of available coffees
                  </li>
                  <li style={styles.listItem}>
                    Creating an optimal blend recipe
                  </li>
                  <li style={styles.listItem}>
                    Returning the blend details to the web app for customer
                    review
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ textAlign: "center" }}>
                  <img
                    src="/images/how/step2.png"
                    alt="Step 2: AI Analysis"
                    style={styles.image}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Step 3 */}
        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Box display="flex" alignItems="center">
              <Typography sx={styles.stepNumber}>3</Typography>
              <Typography variant="h5" sx={styles.sectionTitle}>
                Purchase and Labeling
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography paragraph>
                  Once the customer is satisfied with their custom blend, they
                  proceed to purchase. Our system then:
                </Typography>
                <ul>
                  <li style={styles.listItem}>
                    Guides the customer through the checkout process
                  </li>
                  <li style={styles.listItem}>
                    Generates individual labels for each bag
                  </li>
                  <li style={styles.listItem}>
                    Includes a QR code on the label containing the blend recipe,
                    shipping info, and a link back to your web app
                  </li>
                  <li style={styles.listItem}>
                    Enables easy reordering by scanning the QR code
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ textAlign: "center" }}>
                  <img
                    src="/images/how/step3.png"
                    alt="Step 3: Purchase and Labeling"
                    style={styles.image}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Step 4 */}
        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Box display="flex" alignItems="center">
              <Typography sx={styles.stepNumber}>4</Typography>
              <Typography variant="h5" sx={styles.sectionTitle}>
                Blending and Fulfillment
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography paragraph>
                  Our state-of-the-art blending machines bring the custom recipe
                  to life:
                </Typography>
                <ul>
                  <li style={styles.listItem}>
                    Each blender is equipped with 18 silos, capable of holding
                    almost half a ton of coffee
                  </li>
                  <li style={styles.listItem}>
                    The machine reads the QR code to access the blend recipe
                  </li>
                  <li style={styles.listItem}>
                    Precise amounts of each coffee are selected from the silos
                  </li>
                  <li style={styles.listItem}>
                    The coffees are blended to the exact specifications of the
                    recipe
                  </li>
                  <li style={styles.listItem}>
                    The custom blend is loaded into the bag, ready for shipping
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ textAlign: "center" }}>
                  <img
                    src="/images/how/step4.png"
                    alt="Step 4: Blending and Fulfillment"
                    style={styles.image}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Step 5 */}
        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Box display="flex" alignItems="center">
              <Typography sx={styles.stepNumber}>5</Typography>
              <Typography variant="h5" sx={styles.sectionTitle}>
                Shipping
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography paragraph>
                  The final step ensures the perfect blend reaches your customer
                  efficiently:
                </Typography>
                <ul>
                  <li style={styles.listItem}>
                    The QR code on the label is used for shipping information
                  </li>
                  <li style={styles.listItem}>
                    This integrated approach streamlines the fulfillment process
                  </li>
                  <li style={styles.listItem}>
                    Customers can easily track their unique blend from creation
                    to delivery
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ textAlign: "center" }}>
                  <img
                    src="/images/how/step5.png"
                    alt="Step 5: Shipping"
                    style={styles.image}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/get-started"
          sx={styles.ctaButton}
        >
          Get Started with PerfectBlend AI
        </Button>
      </Box>
    </Box>
  );
};

export default HowItWorks;
