/** @jsxImportSource @emotion/react */
import React from "react";
import useGlobalStyles from "../styles";
import { Box, Typography, Container, Link } from "@mui/material";

const Footer: React.FC = () => {
  const { globalStyles, isSmallScreen, isSmallScreenLandscape } =
    useGlobalStyles();

  const styles = {
    ...globalStyles,
    container: {
      ...globalStyles.container,
      bgcolor: "background.paper",
    },
  };
  return (
    <Box sx={styles.container} component="footer">
      <Typography variant="body1">
        PerfectBlend AI - Revolutionizing the way coffee is blended.
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="/">
          PerfectBlend AI
        </Link>{" "}
        {new Date().getFullYear()}.
      </Typography>
    </Box>
  );
};

export default Footer;
