import React from "react";
import { Box, Typography, Button, Grid, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import useGlobalStyles from "../../styles";

const Home: React.FC = () => {
  const { globalStyles, isSmallScreen } = useGlobalStyles();

  const styles = {
    ...globalStyles,
    container: {
      ...globalStyles.container,
      textAlign: "left", // Change overall text alignment to left
    },
    section: {
      marginTop: "2rem",
    },
    sectionTitle: {
      marginBottom: "1rem",
      fontFamily: "SFBold",
      fontSize: "1.75rem",
    },
    paper: {
      padding: "1.5rem",
      height: "100%",
    },
    highlight: {
      color: "#1976d2",
      fontWeight: "bold",
    },
    ctaButton: {
      marginTop: "2rem",
      padding: "1rem 2rem",
      fontSize: "1.2rem",
    },
    listItem: {
      marginBottom: "0.5rem",
    },
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h1" sx={styles.title}>
        Welcome to PerfectBlend AI
      </Typography>
      <Typography sx={styles.tagline}>
        Revolutionizing Coffee with AI-Powered Custom Blends
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Typography variant="h4" sx={styles.sectionTitle}>
              Precision Blending Meets Artificial Intelligence
            </Typography>
            <Typography paragraph>
              PerfectBlend AI is transforming the coffee industry by seamlessly
              merging advanced AI technology with state-of-the-art blending
              machinery. We empower coffee businesses to offer truly
              personalized blends that cater to each customer's unique taste
              profile.
            </Typography>
            <Typography paragraph>
              Our innovative system guides customers through an interactive
              flavor journey, capturing their unique preferences. Our
              sophisticated AI algorithms then analyze these inputs to create
              bespoke blend recipes from your carefully curated coffee
              inventory.
            </Typography>
            <Typography paragraph>
              The result? A perfect harmony of flavors in every cup, tailored to
              delight each individual palate. With PerfectBlend AI, you're not
              just selling coffee – you're crafting unforgettable personalized
              experiences that keep customers coming back for more.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={styles.paper}>
            <Typography variant="h5" sx={styles.sectionTitle}>
              Cutting-Edge Technology
            </Typography>
            <ul>
              <li style={styles.listItem}>
                AI-powered flavor profile analysis
              </li>
              <li style={styles.listItem}>Custom blend recipe creation</li>
              <li style={styles.listItem}>
                High-precision blending machines (±1% accuracy)
              </li>
              <li style={styles.listItem}>
                18-silo system for versatile blending options
              </li>
              <li style={styles.listItem}>
                QR code integration for seamless processing
              </li>
              <li style={styles.listItem}>
                Capacity of up to 7,000 pounds per day
              </li>
            </ul>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={styles.paper}>
            <Typography variant="h5" sx={styles.sectionTitle}>
              Benefits for Your Business
            </Typography>
            <ul>
              <li style={styles.listItem}>
                Offer personalized coffee blends at scale
              </li>
              <li style={styles.listItem}>
                Increase customer satisfaction and loyalty
              </li>
              <li style={styles.listItem}>
                Streamline your production process
              </li>
              <li style={styles.listItem}>
                Reduce waste and optimize inventory
              </li>
              <li style={styles.listItem}>
                Stand out in the competitive coffee market
              </li>
              <li style={styles.listItem}>
                Integrate seamlessly with your existing systems
              </li>
            </ul>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Typography variant="h5" sx={styles.sectionTitle}>
              The PerfectBlend AI Experience
            </Typography>
            <ol>
              <li style={styles.listItem}>
                Customers engage with our intuitive web app to explore and
                define their unique flavor preferences.
              </li>
              <li style={styles.listItem}>
                Our AI analyzes the profile and crafts a perfect blend recipe
                tailored to their taste.
              </li>
              <li style={styles.listItem}>
                Upon purchase, the custom recipe is seamlessly transmitted to
                our precision blending machine via QR code.
              </li>
              <li style={styles.listItem}>
                The machine selects and combines beans from 18 diverse silos to
                create the bespoke blend with ±1% accuracy.
              </li>
              <li style={styles.listItem}>
                The freshly blended, personalized coffee is carefully packaged
                and shipped directly to your customer.
              </li>
            </ol>
          </Paper>
        </Grid>
      </Grid>

      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/how-it-works"
          sx={styles.ctaButton}
        >
          Discover How It Works
        </Button>
      </Box>

      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          component={Link}
          to="/get-started"
          sx={styles.ctaButton}
        >
          Start Your PerfectBlend AI Journey
        </Button>
      </Box>
    </Box>
  );
};

export default Home;
